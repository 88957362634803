/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import AdminPricingSeasonUpdateRequest from '../model/AdminPricingSeasonUpdateRequest';
import BooleanResponse from '../model/BooleanResponse';
import DateBlock from '../model/DateBlock';
import DateBlockDayIEnumerableResponse from '../model/DateBlockDayIEnumerableResponse';
import DateBlockIEnumerableResponse from '../model/DateBlockIEnumerableResponse';
import DateBlockResponse from '../model/DateBlockResponse';
import DateBlockTypeIEnumerableResponse from '../model/DateBlockTypeIEnumerableResponse';
import DiscountClass from '../model/DiscountClass';
import DiscountClassIEnumerableResponse from '../model/DiscountClassIEnumerableResponse';
import DiscountClassResponse from '../model/DiscountClassResponse';
import PricePointComponentTypeIEnumerableResponse from '../model/PricePointComponentTypeIEnumerableResponse';
import PricingSeason from '../model/PricingSeason';
import PricingSeasonIEnumerableResponse from '../model/PricingSeasonIEnumerableResponse';
import PricingSeasonResponse from '../model/PricingSeasonResponse';

/**
* AdminPricingManagement service.
* @module api/AdminPricingManagementApi
* @version v1
*/
export default class AdminPricingManagementApi {

    /**
    * Constructs a new AdminPricingManagementApi. 
    * @alias module:api/AdminPricingManagementApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DateBlockDayIEnumerableResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminPricingManagementDateBlockDaysGetWithHttpInfo(tenantId) {
      let postBody = null;
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminPricingManagementDateBlockDaysGet");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = DateBlockDayIEnumerableResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminPricingManagement/dateBlockDays', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DateBlockDayIEnumerableResponse}
     */
    v1TenantTenantIdAdminAdminPricingManagementDateBlockDaysGet(tenantId) {
      return this.v1TenantTenantIdAdminAdminPricingManagementDateBlockDaysGetWithHttpInfo(tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {Number} id 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/BooleanResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminPricingManagementDateBlockIdDeleteWithHttpInfo(id, tenantId) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling v1TenantTenantIdAdminAdminPricingManagementDateBlockIdDelete");
      }
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminPricingManagementDateBlockIdDelete");
      }

      let pathParams = {
        'id': id,
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = BooleanResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminPricingManagement/dateBlock/{id}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {Number} id 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/BooleanResponse}
     */
    v1TenantTenantIdAdminAdminPricingManagementDateBlockIdDelete(id, tenantId) {
      return this.v1TenantTenantIdAdminAdminPricingManagementDateBlockIdDeleteWithHttpInfo(id, tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/DateBlock} opts.dateBlock 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DateBlockResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminPricingManagementDateBlockPostWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = opts['dateBlock'];
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminPricingManagementDateBlockPost");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = DateBlockResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminPricingManagement/dateBlock', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/DateBlock} opts.dateBlock 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DateBlockResponse}
     */
    v1TenantTenantIdAdminAdminPricingManagementDateBlockPost(tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminPricingManagementDateBlockPostWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/DateBlock} opts.dateBlock 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DateBlockResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminPricingManagementDateBlockPutWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = opts['dateBlock'];
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminPricingManagementDateBlockPut");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = DateBlockResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminPricingManagement/dateBlock', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/DateBlock} opts.dateBlock 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DateBlockResponse}
     */
    v1TenantTenantIdAdminAdminPricingManagementDateBlockPut(tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminPricingManagementDateBlockPutWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DateBlockTypeIEnumerableResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminPricingManagementDateBlockTypesGetWithHttpInfo(tenantId) {
      let postBody = null;
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminPricingManagementDateBlockTypesGet");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = DateBlockTypeIEnumerableResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminPricingManagement/dateBlockTypes', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DateBlockTypeIEnumerableResponse}
     */
    v1TenantTenantIdAdminAdminPricingManagementDateBlockTypesGet(tenantId) {
      return this.v1TenantTenantIdAdminAdminPricingManagementDateBlockTypesGetWithHttpInfo(tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DateBlockIEnumerableResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminPricingManagementDateBlocksGetWithHttpInfo(tenantId) {
      let postBody = null;
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminPricingManagementDateBlocksGet");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = DateBlockIEnumerableResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminPricingManagement/dateBlocks', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DateBlockIEnumerableResponse}
     */
    v1TenantTenantIdAdminAdminPricingManagementDateBlocksGet(tenantId) {
      return this.v1TenantTenantIdAdminAdminPricingManagementDateBlocksGetWithHttpInfo(tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/DiscountClass} opts.discountClass 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DiscountClassResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminPricingManagementDiscountClassPutWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = opts['discountClass'];
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminPricingManagementDiscountClassPut");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = DiscountClassResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminPricingManagement/discountClass', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/DiscountClass} opts.discountClass 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DiscountClassResponse}
     */
    v1TenantTenantIdAdminAdminPricingManagementDiscountClassPut(tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminPricingManagementDiscountClassPutWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DiscountClassIEnumerableResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminPricingManagementDiscountClassesGetWithHttpInfo(tenantId) {
      let postBody = null;
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminPricingManagementDiscountClassesGet");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = DiscountClassIEnumerableResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminPricingManagement/discountClasses', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DiscountClassIEnumerableResponse}
     */
    v1TenantTenantIdAdminAdminPricingManagementDiscountClassesGet(tenantId) {
      return this.v1TenantTenantIdAdminAdminPricingManagementDiscountClassesGetWithHttpInfo(tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/PricingSeason} opts.pricingSeason 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/PricingSeasonResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminPricingManagementLocationPricingSeasonPostWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = opts['pricingSeason'];
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminPricingManagementLocationPricingSeasonPost");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = PricingSeasonResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminPricingManagement/locationPricingSeason', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/PricingSeason} opts.pricingSeason 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/PricingSeasonResponse}
     */
    v1TenantTenantIdAdminAdminPricingManagementLocationPricingSeasonPost(tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminPricingManagementLocationPricingSeasonPostWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/AdminPricingSeasonUpdateRequest} opts.adminPricingSeasonUpdateRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/PricingSeasonResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminPricingManagementLocationPricingSeasonPutWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = opts['adminPricingSeasonUpdateRequest'];
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminPricingManagementLocationPricingSeasonPut");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = PricingSeasonResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminPricingManagement/locationPricingSeason', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/AdminPricingSeasonUpdateRequest} opts.adminPricingSeasonUpdateRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/PricingSeasonResponse}
     */
    v1TenantTenantIdAdminAdminPricingManagementLocationPricingSeasonPut(tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminPricingManagementLocationPricingSeasonPutWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/PricePointComponentTypeIEnumerableResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminPricingManagementPricePointComponentTypesGetWithHttpInfo(tenantId) {
      let postBody = null;
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminPricingManagementPricePointComponentTypesGet");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = PricePointComponentTypeIEnumerableResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminPricingManagement/pricePointComponentTypes', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/PricePointComponentTypeIEnumerableResponse}
     */
    v1TenantTenantIdAdminAdminPricingManagementPricePointComponentTypesGet(tenantId) {
      return this.v1TenantTenantIdAdminAdminPricingManagementPricePointComponentTypesGetWithHttpInfo(tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/PricingSeason} opts.pricingSeason 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/PricingSeasonResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminPricingManagementPricingSeasonPostWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = opts['pricingSeason'];
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminPricingManagementPricingSeasonPost");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = PricingSeasonResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminPricingManagement/pricingSeason', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/PricingSeason} opts.pricingSeason 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/PricingSeasonResponse}
     */
    v1TenantTenantIdAdminAdminPricingManagementPricingSeasonPost(tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminPricingManagementPricingSeasonPostWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/AdminPricingSeasonUpdateRequest} opts.adminPricingSeasonUpdateRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/PricingSeasonResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminPricingManagementPricingSeasonPutWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = opts['adminPricingSeasonUpdateRequest'];
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminPricingManagementPricingSeasonPut");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = PricingSeasonResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminPricingManagement/pricingSeason', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/AdminPricingSeasonUpdateRequest} opts.adminPricingSeasonUpdateRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/PricingSeasonResponse}
     */
    v1TenantTenantIdAdminAdminPricingManagementPricingSeasonPut(tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminPricingManagementPricingSeasonPutWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/PricingSeasonIEnumerableResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminPricingManagementPricingSeasonsGetWithHttpInfo(tenantId) {
      let postBody = null;
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminPricingManagementPricingSeasonsGet");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = PricingSeasonIEnumerableResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminPricingManagement/pricingSeasons', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/PricingSeasonIEnumerableResponse}
     */
    v1TenantTenantIdAdminAdminPricingManagementPricingSeasonsGet(tenantId) {
      return this.v1TenantTenantIdAdminAdminPricingManagementPricingSeasonsGetWithHttpInfo(tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {Number} locationId 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/PricingSeasonIEnumerableResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminPricingManagementPricingSeasonsLocationIdGetWithHttpInfo(locationId, tenantId) {
      let postBody = null;
      // verify the required parameter 'locationId' is set
      if (locationId === undefined || locationId === null) {
        throw new Error("Missing the required parameter 'locationId' when calling v1TenantTenantIdAdminAdminPricingManagementPricingSeasonsLocationIdGet");
      }
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminPricingManagementPricingSeasonsLocationIdGet");
      }

      let pathParams = {
        'locationId': locationId,
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = PricingSeasonIEnumerableResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminPricingManagement/pricingSeasons/{locationId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {Number} locationId 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/PricingSeasonIEnumerableResponse}
     */
    v1TenantTenantIdAdminAdminPricingManagementPricingSeasonsLocationIdGet(locationId, tenantId) {
      return this.v1TenantTenantIdAdminAdminPricingManagementPricingSeasonsLocationIdGetWithHttpInfo(locationId, tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
