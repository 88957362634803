/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import FeeType from './FeeType';
import PricePointComponentType from './PricePointComponentType';

/**
 * The PricePointComponent model module.
 * @module model/PricePointComponent
 * @version v1
 */
class PricePointComponent {
    /**
     * Constructs a new <code>PricePointComponent</code>.
     * @alias module:model/PricePointComponent
     */
    constructor() { 
        
        PricePointComponent.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>PricePointComponent</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/PricePointComponent} obj Optional instance to populate.
     * @return {module:model/PricePointComponent} The populated <code>PricePointComponent</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new PricePointComponent();

            if (data.hasOwnProperty('dateCreated')) {
                obj['dateCreated'] = ApiClient.convertToType(data['dateCreated'], 'Date');
            }
            if (data.hasOwnProperty('createdBy')) {
                obj['createdBy'] = ApiClient.convertToType(data['createdBy'], 'String');
            }
            if (data.hasOwnProperty('dateLastModified')) {
                obj['dateLastModified'] = ApiClient.convertToType(data['dateLastModified'], 'Date');
            }
            if (data.hasOwnProperty('lastModifiedBy')) {
                obj['lastModifiedBy'] = ApiClient.convertToType(data['lastModifiedBy'], 'String');
            }
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('tenantId')) {
                obj['tenantId'] = ApiClient.convertToType(data['tenantId'], 'String');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('baseAmount')) {
                obj['baseAmount'] = ApiClient.convertToType(data['baseAmount'], 'Number');
            }
            if (data.hasOwnProperty('minAttendeesCharged')) {
                obj['minAttendeesCharged'] = ApiClient.convertToType(data['minAttendeesCharged'], 'Number');
            }
            if (data.hasOwnProperty('amountPerAdditionalAttendee')) {
                obj['amountPerAdditionalAttendee'] = ApiClient.convertToType(data['amountPerAdditionalAttendee'], 'Number');
            }
            if (data.hasOwnProperty('baseAmountAttendees')) {
                obj['baseAmountAttendees'] = ApiClient.convertToType(data['baseAmountAttendees'], 'Number');
            }
            if (data.hasOwnProperty('feeTypeId')) {
                obj['feeTypeId'] = ApiClient.convertToType(data['feeTypeId'], 'Number');
            }
            if (data.hasOwnProperty('feeType')) {
                obj['feeType'] = FeeType.constructFromObject(data['feeType']);
            }
            if (data.hasOwnProperty('pricePointComponentTypeId')) {
                obj['pricePointComponentTypeId'] = ApiClient.convertToType(data['pricePointComponentTypeId'], 'Number');
            }
            if (data.hasOwnProperty('pricePointComponentType')) {
                obj['pricePointComponentType'] = PricePointComponentType.constructFromObject(data['pricePointComponentType']);
            }
        }
        return obj;
    }


}

/**
 * @member {Date} dateCreated
 */
PricePointComponent.prototype['dateCreated'] = undefined;

/**
 * @member {String} createdBy
 */
PricePointComponent.prototype['createdBy'] = undefined;

/**
 * @member {Date} dateLastModified
 */
PricePointComponent.prototype['dateLastModified'] = undefined;

/**
 * @member {String} lastModifiedBy
 */
PricePointComponent.prototype['lastModifiedBy'] = undefined;

/**
 * @member {Number} id
 */
PricePointComponent.prototype['id'] = undefined;

/**
 * @member {String} tenantId
 */
PricePointComponent.prototype['tenantId'] = undefined;

/**
 * @member {String} name
 */
PricePointComponent.prototype['name'] = undefined;

/**
 * @member {Number} baseAmount
 */
PricePointComponent.prototype['baseAmount'] = undefined;

/**
 * @member {Number} minAttendeesCharged
 */
PricePointComponent.prototype['minAttendeesCharged'] = undefined;

/**
 * @member {Number} amountPerAdditionalAttendee
 */
PricePointComponent.prototype['amountPerAdditionalAttendee'] = undefined;

/**
 * @member {Number} baseAmountAttendees
 */
PricePointComponent.prototype['baseAmountAttendees'] = undefined;

/**
 * @member {Number} feeTypeId
 */
PricePointComponent.prototype['feeTypeId'] = undefined;

/**
 * @member {module:model/FeeType} feeType
 */
PricePointComponent.prototype['feeType'] = undefined;

/**
 * @member {Number} pricePointComponentTypeId
 */
PricePointComponent.prototype['pricePointComponentTypeId'] = undefined;

/**
 * @member {module:model/PricePointComponentType} pricePointComponentType
 */
PricePointComponent.prototype['pricePointComponentType'] = undefined;






export default PricePointComponent;

