/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import PricePointMinimalModel from './PricePointMinimalModel';

/**
 * The AdminPricingSeasonUpdateRequest model module.
 * @module model/AdminPricingSeasonUpdateRequest
 * @version v1
 */
class AdminPricingSeasonUpdateRequest {
    /**
     * Constructs a new <code>AdminPricingSeasonUpdateRequest</code>.
     * @alias module:model/AdminPricingSeasonUpdateRequest
     */
    constructor() { 
        
        AdminPricingSeasonUpdateRequest.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>AdminPricingSeasonUpdateRequest</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/AdminPricingSeasonUpdateRequest} obj Optional instance to populate.
     * @return {module:model/AdminPricingSeasonUpdateRequest} The populated <code>AdminPricingSeasonUpdateRequest</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new AdminPricingSeasonUpdateRequest();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('productClassificationId')) {
                obj['productClassificationId'] = ApiClient.convertToType(data['productClassificationId'], 'Number');
            }
            if (data.hasOwnProperty('isActive')) {
                obj['isActive'] = ApiClient.convertToType(data['isActive'], 'Boolean');
            }
            if (data.hasOwnProperty('startDate')) {
                obj['startDate'] = ApiClient.convertToType(data['startDate'], 'Date');
            }
            if (data.hasOwnProperty('endDate')) {
                obj['endDate'] = ApiClient.convertToType(data['endDate'], 'Date');
            }
            if (data.hasOwnProperty('accountingCode')) {
                obj['accountingCode'] = ApiClient.convertToType(data['accountingCode'], 'String');
            }
            if (data.hasOwnProperty('pricePoints')) {
                obj['pricePoints'] = ApiClient.convertToType(data['pricePoints'], [PricePointMinimalModel]);
            }
            if (data.hasOwnProperty('locationId')) {
                obj['locationId'] = ApiClient.convertToType(data['locationId'], 'Number');
            }
            if (data.hasOwnProperty('repeatAnnually')) {
                obj['repeatAnnually'] = ApiClient.convertToType(data['repeatAnnually'], 'Boolean');
            }
        }
        return obj;
    }


}

/**
 * @member {Number} id
 */
AdminPricingSeasonUpdateRequest.prototype['id'] = undefined;

/**
 * @member {Number} productClassificationId
 */
AdminPricingSeasonUpdateRequest.prototype['productClassificationId'] = undefined;

/**
 * @member {Boolean} isActive
 */
AdminPricingSeasonUpdateRequest.prototype['isActive'] = undefined;

/**
 * @member {Date} startDate
 */
AdminPricingSeasonUpdateRequest.prototype['startDate'] = undefined;

/**
 * @member {Date} endDate
 */
AdminPricingSeasonUpdateRequest.prototype['endDate'] = undefined;

/**
 * @member {String} accountingCode
 */
AdminPricingSeasonUpdateRequest.prototype['accountingCode'] = undefined;

/**
 * @member {Array.<module:model/PricePointMinimalModel>} pricePoints
 */
AdminPricingSeasonUpdateRequest.prototype['pricePoints'] = undefined;

/**
 * @member {Number} locationId
 */
AdminPricingSeasonUpdateRequest.prototype['locationId'] = undefined;

/**
 * @member {Boolean} repeatAnnually
 */
AdminPricingSeasonUpdateRequest.prototype['repeatAnnually'] = undefined;






export default AdminPricingSeasonUpdateRequest;

