<template>
  <div class="card-body">
    <label for="spotTypes" class="mb-3">
      <strong>Spot Type(s)</strong>
      <span class="error">*</span>
    </label>
    <div class="form-row justify-content-center">
      <div class="col-12 col-sm-12 col-md-6 form-group text-left m-0">
        <div v-for="type in leftSpotTypes" :key="type.id">
          <label>
            <input
              type="checkbox"
              :id="type.id"
              @change="toggleOption(type.id)"
              :checked="selectedSpotTypeIds.includes(type.id)"
              :disabled="viewOnly"
            />
            <i class="icon mr-1 ml-2" :class="type.icon" />
            {{ type.name }} Spot
          </label>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-md-6 form-group text-left m-0">
        <div v-for="type in rightSpotTypes" :key="type.id">
          <label>
            <input
              type="checkbox"
              :id="type.id"
              @change="toggleOption(type.id)"
              :checked="selectedSpotTypeIds.includes(type.id)"
              :disabled="viewOnly"
            />
            <i class="icon mr-1 ml-2" :class="type.icon" />
            {{ type.name }} Spot
          </label>
        </div>
      </div>
    </div>
    <small class="form-text text-muted mb-2"
      >Assign spot type classification(s) for this particular spot.</small
    >
  </div>
</template>

<script>
import AdminSpotService from "@/services/admin/AdminSpotService.js";

export default {
  name: "SpotTypeForm",
  props: {
    spot: {
      type: Object
    },
    viewOnly: Boolean
  },
  data() {
    return {
      spotTypes: []
    };
  },
  methods: {
    async initialize() {
      const spotService = new AdminSpotService(this.tenantId);
      const response = await spotService.getSpotTypes();
      if (response?.statusCode === "Success") {
        this.spotTypes = response.spotTypes;
      } else {
        this.$store.commit("alert/setErrorAlert", {
          type: "alert-danger",
          message: "Something went wrong...",
          layer: "admin"
        });
      }
    },
    toggleOption(id) {
      let spotSpotTypes = this.parentSpot.spotSpotTypes;
      if (this.onlyAtProductClassLevel(id)) {
        spotSpotTypes.push({
          spotTypeId: id,
          spotId: this.parentSpot.id,
          isSelected: false
        });
      } else if (this.onlyAtSpotLevel(id) || this.atBothLevels(id)) {
        spotSpotTypes = this.parentSpot.spotSpotTypes.filter(
          x => x.spotTypeId !== id
        );
      } else {
        spotSpotTypes.push({
          spotTypeId: id,
          spotId: this.parentSpot.id,
          isSelected: true
        });
      }
      this.parentSpot.spotSpotTypes = spotSpotTypes;
      this.updateParent();
    },
    onlyAtProductClassLevel(id) {
      return (
        !this.parentSpot.spotSpotTypes.map(x => x.spotTypeId).includes(id) &&
        this.parentSpot.productClass.spotTypeIds.includes(id)
      );
    },
    onlyAtSpotLevel(id) {
      return (
        this.parentSpot.spotSpotTypes.map(x => x.spotTypeId).includes(id) &&
        !this.parentSpot.productClass.spotTypeIds.includes(id)
      );
    },
    atBothLevels(id) {
      return (
        this.parentSpot.spotSpotTypes.map(x => x.spotTypeId).includes(id) &&
        this.parentSpot.productClass.spotTypeIds.includes(id)
      );
    },
    updateParent() {
      this.$emit("spot-update", this.parentSpot);
    }
  },
  computed: {
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    },
    columnBreak() {
      var half = Math.floor(this.spotTypes.length / 2);
      var remainder = this.spotTypes.length % 2;
      return remainder != 0 ? half + remainder : half;
    },
    rightSpotTypes() {
      return this.spotTypes.slice(this.columnBreak);
    },
    leftSpotTypes() {
      return this.spotTypes.slice(0, this.columnBreak);
    },
    selectedSpotTypeIds() {
      let spotTypeIds = [];
      if (this.spot.spotSpotTypes.length === 0 && this.spot.productClass) {
        spotTypeIds = this.spot.productClass.spotTypeIds;
      } else if (this.spot.spotSpotTypes.length > 0 && this.spot.productClass) {
        const unSelectedProductClassSpotTypeIds = this.spot.spotSpotTypes
          .filter(x => !x.isSelected)
          .map(x => x.spotTypeId);
        const selectedSpotSpotTypeIds = this.spot.spotSpotTypes
          .filter(x => x.isSelected)
          .map(x => x.spotTypeId);
        const productClassSpotTypeIds = this.spot.productClass.spotTypeIds.filter(
          x => !unSelectedProductClassSpotTypeIds.includes(x)
        );
        spotTypeIds = [...selectedSpotSpotTypeIds, ...productClassSpotTypeIds];
      }
      return spotTypeIds;
    }
  },
  watch: {
    spot() {
      this.parentSpot = JSON.parse(JSON.stringify(this.spot));
    }
  },
  created() {
    this.initialize();
    this.parentSpot = JSON.parse(JSON.stringify(this.spot));
  }
};
</script>

<style scoped>
.icon {
  font-size: 1.2rem;
  color: #999;
  vertical-align: middle;
}
</style>
