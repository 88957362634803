/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The DayBlock model module.
 * @module model/DayBlock
 * @version v1
 */
class DayBlock {
    /**
     * Constructs a new <code>DayBlock</code>.
     * @alias module:model/DayBlock
     */
    constructor() { 
        
        DayBlock.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>DayBlock</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/DayBlock} obj Optional instance to populate.
     * @return {module:model/DayBlock} The populated <code>DayBlock</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new DayBlock();

            if (data.hasOwnProperty('dateCreated')) {
                obj['dateCreated'] = ApiClient.convertToType(data['dateCreated'], 'Date');
            }
            if (data.hasOwnProperty('createdBy')) {
                obj['createdBy'] = ApiClient.convertToType(data['createdBy'], 'String');
            }
            if (data.hasOwnProperty('dateLastModified')) {
                obj['dateLastModified'] = ApiClient.convertToType(data['dateLastModified'], 'Date');
            }
            if (data.hasOwnProperty('lastModifiedBy')) {
                obj['lastModifiedBy'] = ApiClient.convertToType(data['lastModifiedBy'], 'String');
            }
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('tenantId')) {
                obj['tenantId'] = ApiClient.convertToType(data['tenantId'], 'String');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('description')) {
                obj['description'] = ApiClient.convertToType(data['description'], 'String');
            }
            if (data.hasOwnProperty('monday')) {
                obj['monday'] = ApiClient.convertToType(data['monday'], 'Boolean');
            }
            if (data.hasOwnProperty('tuesday')) {
                obj['tuesday'] = ApiClient.convertToType(data['tuesday'], 'Boolean');
            }
            if (data.hasOwnProperty('wednesday')) {
                obj['wednesday'] = ApiClient.convertToType(data['wednesday'], 'Boolean');
            }
            if (data.hasOwnProperty('thursday')) {
                obj['thursday'] = ApiClient.convertToType(data['thursday'], 'Boolean');
            }
            if (data.hasOwnProperty('friday')) {
                obj['friday'] = ApiClient.convertToType(data['friday'], 'Boolean');
            }
            if (data.hasOwnProperty('saturday')) {
                obj['saturday'] = ApiClient.convertToType(data['saturday'], 'Boolean');
            }
            if (data.hasOwnProperty('sunday')) {
                obj['sunday'] = ApiClient.convertToType(data['sunday'], 'Boolean');
            }
        }
        return obj;
    }


}

/**
 * @member {Date} dateCreated
 */
DayBlock.prototype['dateCreated'] = undefined;

/**
 * @member {String} createdBy
 */
DayBlock.prototype['createdBy'] = undefined;

/**
 * @member {Date} dateLastModified
 */
DayBlock.prototype['dateLastModified'] = undefined;

/**
 * @member {String} lastModifiedBy
 */
DayBlock.prototype['lastModifiedBy'] = undefined;

/**
 * @member {Number} id
 */
DayBlock.prototype['id'] = undefined;

/**
 * @member {String} tenantId
 */
DayBlock.prototype['tenantId'] = undefined;

/**
 * @member {String} name
 */
DayBlock.prototype['name'] = undefined;

/**
 * @member {String} description
 */
DayBlock.prototype['description'] = undefined;

/**
 * @member {Boolean} monday
 */
DayBlock.prototype['monday'] = undefined;

/**
 * @member {Boolean} tuesday
 */
DayBlock.prototype['tuesday'] = undefined;

/**
 * @member {Boolean} wednesday
 */
DayBlock.prototype['wednesday'] = undefined;

/**
 * @member {Boolean} thursday
 */
DayBlock.prototype['thursday'] = undefined;

/**
 * @member {Boolean} friday
 */
DayBlock.prototype['friday'] = undefined;

/**
 * @member {Boolean} saturday
 */
DayBlock.prototype['saturday'] = undefined;

/**
 * @member {Boolean} sunday
 */
DayBlock.prototype['sunday'] = undefined;






export default DayBlock;

