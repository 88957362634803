<template>
  <div>
    <div v-if="images.length > 0">
      <div class="row pb-4" v-for="index in numberOfRows" :key="index">
        <div
          class="col-md-3 col-sm-12 pt-2"
          v-for="image in getRowImages(index)"
          :key="image.id"
        >
          <figure class="figure">
            <img
              :src="image.url"
              width="170px"
              height="130px"
              class="rounded"
              alt=""
            />

            <figcaption class="figure-caption">
              <span
                v-if="isPrimaryImage(image.url)"
                class="badge badge-dark featured"
                >DEFAULT</span
              >
              <div>{{ image.title }}</div>
              <div v-if="!viewOnly">
                <button
                  v-if="!isPrimaryImage(image.url)"
                  type="button"
                  class="btn btn-primary btn-sm mr-1"
                  @click="setFeaturedImage(image.url)"
                >
                  Set Default
                </button>
                <button
                  type="button"
                  class="btn btn-secondary btn-sm"
                  @click="deleteImage(image.id)"
                  v-if="deleting[0] != image.id"
                >
                  Delete
                </button>
                <span
                  class="spinner-border spinner-border-sm ml-3"
                  role="status"
                  aria-hidden="true"
                  v-else
                ></span>
              </div>
            </figcaption>
          </figure>
        </div>
      </div>
    </div>
    <div v-else class="mb-3">
      No images associated with this spot.
    </div>
    <button
      type="button"
      class="btn btn-primary"
      @click="addingImage = true"
      v-if="!addingImage && !maxImagesReached && !viewOnly"
    >
      Add Image
      <i class="fa fa-plus-circle" />
    </button>
    <span v-if="maxImagesReached" class="error">
      A maximum of 10 images can be added. Delete an image before adding
      another.
    </span>
    <div v-if="addingImage" class="mt-2">
      <form enctype="multipart/form-data">
        <div class="row">
          <div class="col-4">
            <TextInput
              name="Title"
              id="title"
              placeholder="Enter image title"
              v-model="title"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-4 pt-3">
            <div class="form-group">
              <label for="file">Select Image</label>
              <input
                type="file"
                id="file"
                name="filename"
                class="form-control-file"
                @change="fileChanged($event.target.files)"
              />
              <small
                >Image should be less than 1 MB in size for the upload</small
              >
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-4">
            <button
              class="btn btn-primary mr-1"
              type="button"
              @click="addImage"
              v-if="addingImageSpinner == false && this.file.name"
            >
              Upload Image
            </button>
            <button
              class="btn btn-secondary"
              type="button"
              @click="clear"
              v-if="!addingImageSpinner"
            >
              Cancel
            </button>
            <span
              class="spinner-border spinner-border-sm ml-1"
              role="status"
              aria-hidden="true"
              v-if="addingImageSpinner"
            ></span>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import TextInput from "@/validation/TextInput.vue";
import AdminSpotService from "@/services/admin/AdminSpotService.js";

export default {
  name: "AdminSpotImages",
  components: {
    TextInput
  },
  props: {
    featuredImageUrl: String,
    spotId: Number,
    tenantId: String,
    viewOnly: Boolean
  },
  data() {
    return {
      addingImage: false,
      addingImageSpinner: false,
      title: "",
      file: {},
      deleting: [],
      images: []
    };
  },
  methods: {
    fileChanged(fileList) {
      this.file = fileList[0];
    },
    getRowImages(rowNumber) {
      const index = rowNumber * 4 - 4;
      return this.images.slice(index, index + 4);
    },
    isPrimaryImage(imageUrl) {
      if (imageUrl == this.featuredImageUrl) {
        return true;
      }
      return false;
    },
    addImage() {
      if (this.file.size > 1000000) {
        window.scrollTo(0, 0);
        this.$store.commit("alert/setErrorAlert", {
          type: "alert-danger",
          message:
            "Maximum File size allowed is 1 MB. Please reduce the size of image and retry.",
          layer: "admin"
        });
        this.clear();
        return;
      }
      this.addingImageSpinner = true;
      const service = new AdminSpotService(this.tenantId);
      service
        .addSpotImage(this.spotId, this.title, this.file)
        .then(response => {
          if (response?.statusCode == "Success") {
            this.imagesUpdated().then(() => {
              if (!this.featuredImageUrl || this.featuredImageUrl == "") {
                this.setFeaturedImage(this.images[0].url);
              }
              this.clear();
            });
          } else {
            window.scrollTo(0, 0);
            this.$store.commit("alert/setErrorAlert", {
              type: "alert-danger",
              message: "Something went wrong...",
              layer: "admin"
            });
            this.clear();
          }
        });
    },
    deleteImage(imageId) {
      this.deleting.push(imageId);
      const image = this.images.find(i => i.id == imageId);
      const service = new AdminSpotService(this.tenantId);
      service.deleteSpotImage(this.spotId, imageId).then(() => {
        this.imagesUpdated().then(() => {
          this.deleting.splice(0, 1);
          if (this.isPrimaryImage(image.url)) {
            //Set first image as default image
            if (this.images.length > 0) {
              this.setFeaturedImage(this.images[0].url);
            } else {
              this.setFeaturedImage("");
            }
          }
        });
      });
    },
    setFeaturedImage(imageUrl) {
      this.$emit("featuredImageUpdated", imageUrl);
    },
    imagesUpdated() {
      return this.getSpotImages();
    },
    clear() {
      this.addingImage = false;
      this.addingImageSpinner = false;
      this.title = "";
      this.file = {};
    },
    getSpotImages() {
      const service = new AdminSpotService(this.tenantId);
      return service.getSpotImages(this.spotId).then(response => {
        this.images = response.data;
      });
    }
  },

  computed: {
    numberOfRows() {
      if (!this.images) {
        return 0;
      }
      return Math.ceil(this.images.length / 4);
    },
    maxImagesReached() {
      if (this.images.length > 9) {
        return true;
      }
      return false;
    }
  },
  created() {
    this.getSpotImages();
  }
};
</script>

<style scoped>
.featured {
  position: absolute;
  z-index: 1;
  margin-left: 5px;
  margin-top: -125px;
}
</style>
