<template>
  <div>
    <h3>
      Spot Detail
      <hr />
    </h3>
    <FormErrorAlert
      v-if="errors.length > 0"
      :errors="errors"
      :formRef="this.$refs.newSpotForm"
    />
    <div v-if="!loading">
      <ValidationObserver ref="newSpotForm">
        <form @submit.prevent="onSubmit">
          <div role="tablist">
            <div class="card mb-1">
              <div class="card-header" role="tab" v-b-toggle.spot-information>
                <i
                  class="fas fa-chevron-up when-open card-title float-right mt-1 mb-0"
                ></i>
                <i
                  class="fas fa-chevron-down when-closed card-title float-right mt-1 mb-0"
                ></i>
                <div class="card-title mb-0">Primary Spot Information</div>
              </div>
              <b-collapse
                id="spot-information"
                accordion="admin-spot-details"
                visible
                role="tabpanel"
              >
                <PrimarySpotInformationForm
                  :spot="spot"
                  @spot-update="updateSpot"
                  :viewOnly="boolView"
                />
              </b-collapse>
            </div>
            <div class="card mb-1">
              <div
                class="card-header"
                role="tab"
                v-b-toggle.spot-type-classification
              >
                <i
                  class="fas fa-chevron-up when-open card-title float-right mt-1 mb-0"
                ></i>
                <i
                  class="fas fa-chevron-down when-closed card-title float-right mt-1 mb-0"
                ></i>
                <div class="card-title mb-0">Spot Type Classification</div>
              </div>
              <b-collapse
                id="spot-type-classification"
                accordion="admin-spot-details"
                role="tabpanel"
              >
                <SpotTypeForm
                  :spot="spot"
                  @spot-update="updateSpot"
                  :viewOnly="boolView"
                />
              </b-collapse>
            </div>

            <div class="card mb-1">
              <div class="card-header" role="tab" v-b-toggle.spot-attributes>
                <i
                  class="fas fa-chevron-up when-open card-title float-right mt-1 mb-0"
                ></i>
                <i
                  class="fas fa-chevron-down when-closed card-title float-right mt-1 mb-0"
                ></i>
                <div class="card-title mb-0">Attributes</div>
              </div>
              <b-collapse
                id="spot-attributes"
                accordion="admin-spot-details"
                role="tabpanel"
              >
                <div class="card-body">
                  <div v-if="product.attributes">
                    <AdminProductClassAttributes
                      :classAttributes="product.attributes"
                      :allAttributes="allAttributes"
                      :spotAttributes="spot.attributes"
                      @updated="attributesUpdated"
                      :viewOnly="boolView"
                      mode="spot"
                    />
                  </div>
                  <div v-else>
                    Please select a product class to configure attributes.
                  </div>
                </div>
              </b-collapse>
            </div>

            <div class="card mb-1">
              <div class="card-header" role="tab" v-b-toggle.spot-images>
                <i
                  class="fas fa-chevron-up when-open card-title float-right mt-1 mb-0"
                ></i>
                <i
                  class="fas fa-chevron-down when-closed card-title float-right mt-1 mb-0"
                ></i>
                <div class="card-title mb-0">Spot Images</div>
              </div>
              <b-collapse
                id="spot-images"
                accordion="admin-spot-details"
                role="tabpanel"
              >
                <div class="card-body">
                  <AdminSpotImages
                    v-if="spot.id"
                    @featuredImageUpdated="featuredImageUpdated"
                    :tenantId="tenantId"
                    :spotId="spot.id"
                    :featuredImageUrl="spot.featuredImageUrl"
                    :viewOnly="boolView"
                  />
                </div>
              </b-collapse>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="mt-4">
                <div v-if="unsavedChanges" class="error mb-2">
                  You have unsaved changes.
                </div>
                <button
                  type="submit"
                  v-show="!boolView"
                  class="btn btn-lg btn-primary mr-2"
                >
                  Save
                </button>
                <button
                  type="button"
                  v-show="boolView"
                  class="btn btn-lg btn-primary mr-2"
                  @click="modify"
                >
                  Modify
                </button>
                <button
                  class="btn btn-cancel btn-lg mr-2"
                  @click="cancel"
                  type="button"
                >
                  Back
                </button>
              </div>
              <p class="mt-2">
                <span style="color: red;">*</span> Indicates a required field
              </p>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </div>
    <div v-else class="text-center">
      <span
        class="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
      ></span>
    </div>
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import FormErrorAlert from "@/components/alert/FormErrorAlert.vue";
import PrimarySpotInformationForm from "@/components/admin/spotdetails/PrimarySpotInformationForm";
import SpotTypeForm from "@/components/admin/spotdetails/SpotTypeForm";
import AdminSpotService from "@/services/admin/AdminSpotService.js";
import AdminProductClassAttributes from "@/components/admin/productclass/AdminProductClassAttributes.vue";
import AdminTenantService from "@/services/admin/AdminTenantService.js";
import AdminProductManagementService from "@/services/admin/AdminProductManagementService.js";
import AdminSpotImages from "@/components/admin/AdminSpotImages.vue";

export default {
  name: "AdminSpotDetail",
  title: "Admin - Spot Detail",
  components: {
    ValidationObserver,
    FormErrorAlert,
    PrimarySpotInformationForm,
    AdminProductClassAttributes,
    AdminSpotImages,
    SpotTypeForm
  },
  props: {
    spotId: {
      type: String,
      default: ""
    },
    view: {
      type: String
    }
  },
  data() {
    return {
      boolView: true,
      loading: false,
      spot: {},
      allAttributes: [],
      product: {},
      unsavedChanges: false,
      errors: []
    };
  },
  methods: {
    async initialize() {
      this.boolView = this.view === "true";
      this.loading = true;
      const spotService = new AdminSpotService(this.tenantId);
      const response = await spotService.getSpot(this.spotId);
      if (response?.statusCode === "Success") {
        this.spot = response.data;
        this.spot.productClass.spotTypeIds = this.spot.productClass.productClassificationSpotTypes.map(
          x => x.spotTypeId
        );
        this.spot.productClass.equipmentTypeIds = this.spot.productClass.productClassificationEquipmentTypes.map(
          x => x.equipmentTypeId
        );
        this.loading = false;
      } else {
        this.loading = false;
        this.$store.commit("alert/setErrorAlert", {
          type: "alert-danger",
          message: "Something went wrong...",
          layer: "admin"
        });
        window.scrollTo(0, 0);
      }
    },
    async onSubmit() {
      if (this.spotTypeValidation()) return;
      this.$refs.newSpotForm.validate().then(async success => {
        if (!success) {
          setTimeout(() => {
            const errors = Object.entries(this.$refs.newSpotForm.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error["value"].length);
            this.errors = errors;
            this.$refs.newSpotForm.refs[errors[0]["key"]].$el.scrollIntoView({
              behavior: "smooth",
              block: "center"
            });
          }, 100);
        } else {
          this.errors = [];
          try {
            this.spot.tenantId = this.tenantId;
            const spotService = new AdminSpotService(this.tenantId);
            const response = await spotService.saveSpot(this.spot);
            if (response?.statusCode === "Success") {
              this.$store.commit("alert/setErrorAlert", {
                type: "alert-success",
                message: "Spot information updated",
                layer: "admin"
              });
              window.scrollTo(0, 0);
              this.unsavedChanges = false;
            } else {
              this.$store.commit("alert/setErrorAlert", {
                type: "alert-danger",
                message: "Something went wrong...",
                layer: "admin"
              });
              window.scrollTo(0, 0);
            }
          } catch (err) {
            this.$store.commit("alert/setErrorAlert", {
              type: "alert-danger",
              message: "Something went wrong...",
              layer: "admin"
            });
          }
        }
      });
    },
    cancel() {
      this.$router.go(-1);
    },
    modify() {
      this.boolView = false;
    },
    updateSpot(e) {
      const updatedSpot = { ...this.spot, ...e };
      this.unsavedChanges =
        JSON.stringify(this.spot) !== JSON.stringify(updatedSpot);
      this.spot = updatedSpot;
    },
    getProduct() {
      if (this.spot.productId) {
        const service = new AdminProductManagementService(this.tenantId);
        service.getProduct(this.spot.productId).then(response => {
          this.product = response;
        });
      }
    },
    getAttributes() {
      const service = new AdminTenantService(this.tenantId);
      service.getAttributes().then(response => {
        this.allAttributes = response.data;
      });
    },
    attributesUpdated(attributes) {
      const spot = { ...this.spot, attributes: [...attributes] };
      this.updateSpot(spot);
    },
    featuredImageUpdated(imageUrl) {
      this.spot.featuredImageUrl = imageUrl;
      this.onSubmit();
    },
    spotTypeValidation() {
      if (
        this.spot.spotSpotTypes.length > 0 ||
        this.spot.productClass.spotTypeIds.length > 0
      ) {
        return false;
      } else {
        this.$store.commit("alert/setErrorAlert", {
          type: "alert-danger",
          message: "Spot must contain one Spot Type.",
          layer: "admin"
        });
        return true;
      }
    }
  },
  computed: {
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    }
  },
  watch: {
    "spot.productId"() {
      this.getProduct();
    }
  },
  created() {
    this.initialize();
    this.getProduct();
    this.getAttributes();
  }
};
</script>

<style scoped>
.card-header {
  cursor: pointer;
}
.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
</style>
