<template>
  <div class="card-body">
    <div class="row">
      <div class="col-md-6 col-sm-12">
        <ValidationProvider
          rules="required"
          name="Park"
          v-slot="{ errors, ariaInput, ariaMsg }"
        >
          <label
            @click="$refs.select.focus()"
            :class="{ error: errors[0] }"
            for="park"
          >
            Park Name
            <span class="error">*</span>
          </label>
          <select
            class="form-control form-control-lg"
            id="park"
            v-model="parentSpot.locationId"
            v-bind="ariaInput"
            @change="locationUpdated"
            :disabled="viewOnly"
          >
            <option :value="''" aria-placeholder="Select Park...">
              Select Park...
            </option>
            <option
              v-for="location in locations"
              :key="location.id"
              :value="location.id"
              aria-placeholder="Select Park..."
            >
              {{ location.longName }}
            </option>
          </select>
          <ul class="mt-1 mb-0 pl-3" v-if="errors.length > 0">
            <li
              v-for="(error, index) in errors"
              :key="index"
              class="error"
              v-bind="ariaMsg"
            >
              {{ error }}
            </li>
          </ul>
        </ValidationProvider>

        <small class="form-text text-muted mb-2">The park’s full name.</small>
      </div>
      <div class="col-md-6 col-sm-12">
        <TextInput
          name="Spot Name"
          id="spotName"
          rules="required|min:3|max:50"
          v-model="parentSpot.name"
          @input="updateParent"
          :disabled="viewOnly"
        />
        <small class="form-text text-muted mb-2"
          >The name for the spot, shown in spot details. If the spot name is a
          single or double digit number, please prefix with leading 0's to meet
          the spot name length requirement. For example, if a spot's name is '1'
          please enter '001'.</small
        >
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 col-sm-12">
        <label @click="$refs.select.focus()" for="area">
          Campground
        </label>
        <select
          class="form-control form-control-lg"
          id="area"
          v-model="parentSpot.areaId"
          :disabled="viewOnly || !parentSpot.locationId"
          @change="updateParent"
        >
          <option :value="''" aria-placeholder="Select Campground...">
            Select Campground...
          </option>
          <option
            v-for="area in campgrounds"
            :key="area.id"
            :value="area.id"
            aria-placeholder="Select Campground..."
          >
            {{ area.name }}
          </option>
        </select>
        <small class="form-text text-muted mb-2"
          >The name of the campground associated to the spot.</small
        >
      </div>
      <div class="col-md-6 col-sm-12">
        <ValidationProvider
          rules="required"
          name="Loop"
          v-slot="{ errors, ariaInput, ariaMsg }"
        >
          <label
            @click="$refs.select.focus()"
            :class="{ error: errors[0] }"
            for="loop"
          >
            Loop
            <span class="error">*</span>
          </label>
          <select
            class="form-control form-control-lg"
            id="loop"
            v-model="parentSpot.loopId"
            v-bind="ariaInput"
            @change="updateParent"
            :disabled="viewOnly || !parentSpot.locationId"
          >
            <option :value="''" aria-placeholder="Select Loop...">
              Select Loop...
            </option>
            <option
              v-for="loop in loops"
              :key="loop.id"
              :value="loop.id"
              aria-placeholder="Select Loop..."
            >
              {{ loop.name }}
            </option>
          </select>
          <ul class="mt-1 mb-0 pl-3" v-if="errors.length > 0">
            <li
              v-for="(error, index) in errors"
              :key="index"
              class="error"
              v-bind="ariaMsg"
            >
              {{ error }}
            </li>
          </ul>
        </ValidationProvider>
        <small class="form-text text-muted mb-2"
          >The name of the loop associated to the spot.</small
        >
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 col-sm-12">
        <ValidationProvider
          name="Product Class"
          rules="required"
          v-slot="{ errors, ariaInput, ariaMsg }"
        >
          <label
            @click="$refs.select.focus()"
            :class="{ error: errors[0] }"
            for="productClass"
            >Product Class
            <span class="error">*</span>
          </label>
          <select
            class="form-control form-control-lg"
            id="productClass"
            v-model="parentSpot.productId"
            v-bind="ariaInput"
            @change="updateProductClass"
            :disabled="viewOnly"
          >
            <option :value="''" aria-placeholder="Select Product Class...">
              Select Product Class...
            </option>
            <option
              v-for="item in productClasses"
              :key="item.id"
              :value="item.productIds[0]"
              aria-placeholder="Select Product Class..."
            >
              {{ item.name }}{{ ` - P${(item.id + "").padStart(3, "0")}` }}
            </option>
          </select>
          <ul class="mt-1 mb-0 pl-3" v-if="errors.length > 0">
            <li
              v-for="(error, index) in errors"
              :key="index"
              class="error"
              v-bind="ariaMsg"
            >
              {{ error }}
            </li>
          </ul>
        </ValidationProvider>
        <small class="form-text text-muted mb-2"
          >The product class associated to the spot.</small
        >
      </div>
      <div :class="isAgency ? 'col-md-3 col-sm-12' : 'col-md-6 col-sm-12'">
        <ValidationProvider
          rules="required"
          name="Sales Channel"
          v-slot="{ errors, ariaInput, ariaMsg }"
        >
          <label
            @click="$refs.select.focus()"
            :class="{ error: errors[0] }"
            for="salesChannel"
          >
            Sales Channel
            <span class="error">*</span>
          </label>
          <select
            class="form-control form-control-lg"
            id="salesChannel"
            v-model="parentSpot.salesChannelId"
            v-bind="ariaInput"
            @change="updateParent"
            :disabled="viewOnly"
          >
            <option :value="''" aria-placeholder="Select Sales Channel...">
              Select Sales Channel...
            </option>
            <option
              v-for="item in salesChannels"
              :key="item.id"
              :value="item.id"
              aria-placeholder="Select Sales Channel..."
            >
              {{ item.name }}
            </option>
          </select>
          <ul class="mt-1 mb-0 pl-3" v-if="errors.length > 0">
            <li
              v-for="(error, index) in errors"
              :key="index"
              class="error"
              v-bind="ariaMsg"
            >
              {{ error }}
            </li>
          </ul>
        </ValidationProvider>
        <small class="form-text text-muted mb-2"
          >The sales channels that the spot will be available to.</small
        >
      </div>
      <div class="col-md-3 col-sm-12 mt-3" v-if="isAgency">
        <label class="mt-4">
          <input
            type="checkbox"
            v-model="parentSpot.showAsWalkIn"
            id="showAsWalkIn"
            @change="updateParent"
            :disabled="viewOnly"
          />
          Show spot on customer site as Walk-in
        </label>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3 col-sm-6">
        <TextInput
          name="Latitude"
          id="latitude"
          rules="required|decimal|min:2|max:70"
          v-model="parentSpot.latitude"
          @input="updateParent"
          :disabled="viewOnly"
        />
        <small class="form-text text-muted mb-2">
          The GPS coordinates for the spot. Used to locate the spot on the park
          map.
        </small>
      </div>
      <div class="col-md-3 col-sm-6">
        <TextInput
          name="Longitude"
          id="longitude"
          rules="required|decimal|min:2|max:70"
          v-model="parentSpot.longitude"
          @input="updateParent"
          :disabled="viewOnly"
        />
        <small class="form-text text-muted mb-2">
          The GPS coordinates for the spot. Used to locate the spot on the park
          map.
        </small>
      </div>
      <div class="col-md-6 col-sm-12">
        <ValidationProvider
          name="Equipment Types"
          rules="required"
          v-slot="{ errors, ariaInput, ariaMsg }"
        >
          <label
            @click="$refs.select.focus()"
            :class="{ error: errors[0] }"
            for="equipmentTypes"
          >
            Equipment Type(s)
            <span class="error">*</span>
          </label>
          <multiselect
            v-model="selectedEquipmentTypes"
            label="name"
            track-by="id"
            :multiple="true"
            id="equipmentTypes"
            ref="equipmentTypes"
            :clear-on-select="false"
            :preserve-search="true"
            open-direction="bottom"
            placeholder="None"
            :options="equipmentTypes"
            :disabled="viewOnly"
            @select="selectEquipmentType"
            @remove="removeEquipmentType"
            class="spotEquipmentTypes"
            v-bind="ariaInput"
          >
            <template slot="tag" slot-scope="{ option, remove }">
              <span class="multiselect__tag" :key="option.id">
                <span>{{ option.name }}</span>
                <i
                  tabindex="1"
                  @keypress.enter.prevent="remove(option)"
                  @mousedown.prevent="remove(option)"
                  class="multiselect__tag-icon"
                ></i>
              </span>
            </template>
          </multiselect>

          <ul class="mt-1 mb-0 pl-3" v-if="errors.length > 0">
            <li
              v-for="(error, index) in errors"
              :key="index"
              class="error"
              v-bind="ariaMsg"
            >
              {{ error }}
            </li>
          </ul>
        </ValidationProvider>
        <small class="form-text text-muted mb-2"
          >The equipment allowed for this spot.</small
        >
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import TextInput from "@/validation/TextInput.vue";
import AdminLocationService from "@/services/admin/AdminLocationService.js";
import AdminProductManagementService from "@/services/admin/AdminProductManagementService.js";
import AdminLookupService from "@/services/admin/AdminLookupService.js";
import Multiselect from "vue-multiselect";
import checkPermissionMixin from "@/mixins/PermissionCheckMixin.js";

export default {
  name: "PrimarySpotInformationForm",
  mixins: [checkPermissionMixin],
  components: {
    ValidationProvider,
    TextInput,
    Multiselect
  },
  props: {
    spot: {
      type: Object
    },
    viewOnly: Boolean
  },
  data() {
    return {
      parentSpot: {},
      campgrounds: [],
      loops: [],
      locations: [],
      productClasses: [],
      salesChannels: [],
      equipmentTypes: [],
      selectedEquipmentTypes: []
    };
  },
  methods: {
    async initialize() {
      const service = new AdminLocationService(this.tenantId);
      const productService = new AdminProductManagementService(this.tenantId);
      const dataService = new AdminLookupService(this.tenantId);
      const locations = service.getLocations();
      const classes = productService.getAllProductClasses();
      const salesChannels = dataService.getAllSalesChannels();
      const equipmentTypes = dataService.getEquipmentTypes();
      await Promise.allSettled([
        locations,
        classes,
        salesChannels,
        equipmentTypes
      ]).then(responses => {
        if (
          responses[0].value.statusCode === "Success" &&
          responses[1].value.length > 0 &&
          responses[2].value.length > 0
        ) {
          this.locations = responses[0].value.data.filter(loc =>
            this.checkLocationPermission("ParkManagementAddNewSpot", loc.id)
          );
          this.productClasses = responses[1].value;
          this.salesChannels = responses[2].value;
          this.equipmentTypes = responses[3].value.data;
          if (this.parentSpot.id) {
            this.getLocationData();
            this.setEquipmentTypes();
          }
        } else {
          this.loading = false;
          this.$store.commit("alert/setErrorAlert", {
            type: "alert-danger",
            message: "Something went wrong...",
            layer: "admin"
          });
        }
      });
    },
    async locationUpdated() {
      this.parentSpot.areaId = "";
      this.parentSpot.loopId = "";
      this.updateParent();
      this.getLocationData();
    },
    async getLocationData() {
      const service = new AdminLocationService(this.tenantId);
      const campgrounds = service.getAreas(this.parentSpot.locationId);
      const loops = service.getLoops(this.parentSpot.locationId);
      await Promise.allSettled([campgrounds, loops]).then(responses => {
        if (
          responses[0].value.statusCode === "Success" &&
          responses[1].value.statusCode === "Success"
        ) {
          this.campgrounds = responses[0].value.lookupValues;
          this.loops = responses[1].value.lookupValues;
        } else {
          this.loading = false;
          this.$store.commit("alert/setErrorAlert", {
            type: "alert-danger",
            message: "Something went wrong...",
            layer: "admin"
          });
        }
      });
    },
    updateProductClass() {
      this.parentSpot.productClass = this.productClasses.find(x =>
        x.productIds.includes(this.parentSpot.productId)
      );
      this.selectedEquipmentTypes = this.equipmentTypes.filter(x =>
        this.parentSpot.productClass.equipmentTypeIds.includes(x.id)
      );
      this.parentSpot.spotEquipmentTypes = [];
      this.updateParent();
    },
    selectEquipmentType(type) {
      this.parentSpot.spotEquipmentTypes = this.parentSpot.spotEquipmentTypes.filter(
        x => x.equipmentTypeId != type.id
      );
      if (!this.parentSpot.productClass.equipmentTypeIds.includes(type.id)) {
        this.parentSpot.spotEquipmentTypes.push({
          spotId: this.parentSpot.id,
          equipmentTypeId: type.id,
          isSelected: true
        });
      }
      this.updateParent();
    },
    removeEquipmentType(type) {
      this.parentSpot.spotEquipmentTypes = this.parentSpot.spotEquipmentTypes.filter(
        x => x.equipmentTypeId != type.id
      );
      if (this.parentSpot.productClass.equipmentTypeIds.includes(type.id)) {
        this.parentSpot.spotEquipmentTypes.push({
          spotId: this.parentSpot.id,
          equipmentTypeId: type.id,
          isSelected: false
        });
      }
      this.updateParent();
    },
    setEquipmentTypes() {
      this.equipmentTypes.forEach(x => {
        if (
          this.parentSpot.spotEquipmentTypes
            .filter(et => et.isSelected)
            .map(et => et.equipmentTypeId)
            .includes(x.id)
        ) {
          this.selectedEquipmentTypes.push(x);
        } else if (
          this.parentSpot.productClass.equipmentTypeIds?.includes(x.id) &&
          !this.parentSpot.spotEquipmentTypes
            .filter(et => !et.isSelected)
            .map(et => et.equipmentTypeId)
            .includes(x.id)
        ) {
          this.selectedEquipmentTypes.push(x);
        }
      });
    },
    updateParent() {
      if (!this.isAgency && this.parentSpot.salesChannelId !== 2) {
        this.parentSpot.showAsWalkIn = false;
      }
      this.$emit("spot-update", this.parentSpot);
    }
  },
  computed: {
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    },
    isAgency() {
      return this.salesChannels.find(
        x => x.id === this.parentSpot.salesChannelId && x.id == 2
      );
    }
  },
  watch: {
    spot() {
      this.parentSpot = JSON.parse(JSON.stringify(this.spot));
    }
  },
  mounted() {
    this.initialize();
    this.parentSpot = JSON.parse(JSON.stringify(this.spot));
    this.$refs.equipmentTypes.$refs.search.setAttribute("autocomplete", "off");
  }
};
</script>
