/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The PricePointComponentMinimalModel model module.
 * @module model/PricePointComponentMinimalModel
 * @version v1
 */
class PricePointComponentMinimalModel {
    /**
     * Constructs a new <code>PricePointComponentMinimalModel</code>.
     * @alias module:model/PricePointComponentMinimalModel
     */
    constructor() { 
        
        PricePointComponentMinimalModel.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>PricePointComponentMinimalModel</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/PricePointComponentMinimalModel} obj Optional instance to populate.
     * @return {module:model/PricePointComponentMinimalModel} The populated <code>PricePointComponentMinimalModel</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new PricePointComponentMinimalModel();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('baseAmount')) {
                obj['baseAmount'] = ApiClient.convertToType(data['baseAmount'], 'Number');
            }
            if (data.hasOwnProperty('baseAmountAttendees')) {
                obj['baseAmountAttendees'] = ApiClient.convertToType(data['baseAmountAttendees'], 'Number');
            }
            if (data.hasOwnProperty('amountPerAdditionalAttendee')) {
                obj['amountPerAdditionalAttendee'] = ApiClient.convertToType(data['amountPerAdditionalAttendee'], 'Number');
            }
        }
        return obj;
    }


}

/**
 * @member {Number} id
 */
PricePointComponentMinimalModel.prototype['id'] = undefined;

/**
 * @member {Number} baseAmount
 */
PricePointComponentMinimalModel.prototype['baseAmount'] = undefined;

/**
 * @member {Number} baseAmountAttendees
 */
PricePointComponentMinimalModel.prototype['baseAmountAttendees'] = undefined;

/**
 * @member {Number} amountPerAdditionalAttendee
 */
PricePointComponentMinimalModel.prototype['amountPerAdditionalAttendee'] = undefined;






export default PricePointComponentMinimalModel;

